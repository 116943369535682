<nav aria-label="breadcrumb">
  <ol class="matero-breadcrumb">
    @for (navLink of nav; track trackByNavlink($index, navLink); let isFirst = $first) {
      <li class="matero-breadcrumb-item">
        @if (isFirst) {
          <a routerLink="/" class="link">{{navLink}}</a>
        }
        @if (!isFirst) {
          <mat-icon class="chevron">chevron_right</mat-icon>
          <span>{{navLink | translate}}</span>
        }
      </li>
    }
  </ol>
</nav>
