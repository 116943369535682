.matero-breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin-bottom: 1rem;
  font-size: .875rem;
  list-style: none;
}

.matero-breadcrumb-item {
  line-height: 1.125rem;
  text-transform: capitalize;

  > * {
    vertical-align: middle;
  }

  > a.link {
    color: currentColor;
    text-decoration: none;

    &:hover {
      color: currentColor;
      text-decoration: underline;
    }
  }

  > .chevron {
    width: 1.125rem;
    height: 1.125rem;
    font-size: 1.125rem;
    user-select: none;
  }
}
